<template>
  <v-data-table
    dense
    :headers="usageSummaryHeaders"
    :items="summaryData"
    :items-per-page="-1"
    hide-actions
    disable-initial-sort
    class="elevation-1"
    :expand="expand"
    item-key="usage_category"
  >
    <template v-slot:items="props">
      <tr
        @click="() => handleTableRowClick(props)"
        :class="props.item.usage_category !== 'Total' ? selectedUsageCategory === props.item.usage_category ? 'selected-tr hover-cursor-change' : 'hover-cursor-change' : ''"
      >
        <td>{{ props.item.usage_category }}</td>
        <td>{{ props.item.total_hours }}</td>
        <td>{{ percentFormat(props.item.total_percent) }}</td>
        <td>{{ usdFormat(props.item.invoice_total) }}</td>
        <td>{{ percentFormat(props.item.invoice_percent) }}</td>
      </tr>
    </template>
    <template v-slot:expand="props">
      <v-data-table
        dense
        :headers="categoreyDetailsHeaders"
        :items="props.item.reservation_details"
        :items-per-page="-1"
        hide-actions
        disable-initial-sort
        class="elevation-1"
        :expand="expand"
        item-key="usage_category_details"
      >
        <template v-slot:items="reservation_detail_props">
          <tr class="selected-inner-tr">
            <td>{{ dateFormat(reservation_detail_props.item.service_date) }}</td>
            <td>{{ reservation_detail_props.item.title }}</td>
            <td>{{ reservation_detail_props.item.description || 'N/A' }}</td>
            <td>{{ reservation_detail_props.item.billed_service }}</td>
            <td>${{ reservation_detail_props.item.invoiced }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-data-table>
</template>
<script>
import { format } from 'date-fns'

export default {
  props: {
    summaryData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedUsageCategory: '',
      expand: false,
      usageSummaryHeaders: [
        {
          text: 'Usage Type',
          value: 'usage_category',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Total Hours',
          value: 'total_hours',
          sortable: true
        },
        {
          text: 'Total Hours %',
          value: 'total_percent',
          sortable: true
        },
        {
          text: 'Invoice Total',
          value: 'invoice_total',
          sortable: true
        },
        {
          text: 'Invoice Total %',
          value: 'invoice_percent',
          sortable: true
        }
      ],
      categoreyDetailsHeaders: [
        {
          text: 'Service Date',
          value: 'service_date',
          sortable: true,
          align: 'start',
          class: 'selected-inner-tr'
        },
        {
          text: 'Title',
          value: 'title',
          sortable: true,
          align: 'start',
          class: 'selected-inner-tr'
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
          align: 'start',
          class: 'selected-inner-tr'
        },
        {
          text: 'Billed Service',
          value: 'billed_service',
          sortable: true,
          align: 'start',
          class: 'selected-inner-tr'
        },
        {
          text: 'Invoiced',
          value: 'invoiced',
          sortable: true,
          align: 'start',
          class: 'selected-inner-tr'
        }
      ]
    }
  },
  methods: {
    handleTableRowClick (rowProps) {
      // props.item.usage_category !== 'Total' ? props.expanded = !props.expanded : null
      if (this.selectedUsageCategory === rowProps.item.usage_category) {
        this.selectedUsageCategory = ''
        rowProps.expanded = !rowProps.expanded
        return
      }
      if (rowProps.item.usage_category !== 'Total') {
        rowProps.expanded = !rowProps.expanded
        this.selectedUsageCategory = rowProps.item.usage_category
      }
    },
    usdFormat (amount) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(amount)
    },
    percentFormat (percent) {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2
      }).format(percent)
    },
    dateFormat (date) {
      return format(date, 'YYYY-MM-DD')
    }
  }
}
</script>
<style>
.selected-tr {
  background-color: #f5c401;
}
.selected-inner-tr {
  background-color: #f7eec7;
}
.hover-cursor-change:hover {
  cursor:pointer
}
</style>
