<template>
  <v-container
    fluid
    grid-list-md
  >
    <v-layout>
      <v-btn
        flat
        color="primary"
        @click="changeView('History')"
      >
        History
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click="changeView('Reports')"
      >
        Reports
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click="changeView('Billing')"
      >
        Billing
      </v-btn>
    </v-layout>
    <UsageHistory v-if="showHistory" />
    <UsageReports v-if="showReports" />
    <Billing v-if="showBilling" />
  </v-container>
</template>

<script>
import Billing from './Billing'
import UsageHistory from './UsageHistory'
import UsageReports from './UsageReports'
export default {
  components: {
    Billing,
    UsageHistory,
    UsageReports
  },
  data () {
    return {
      showHistory: true,
      showBilling: false,
      showReports: false
    }
  },
  methods: {
    changeView (view) {
      switch (view) {
        case 'History':
          this.showHistory = true
          this.showBilling = false
          this.showReports = false
          break
        case 'Billing':
          this.showHistory = false
          this.showBilling = true
          this.showReports = false
          break
        case 'Reports':
          this.showHistory = false
          this.showBilling = false
          this.showReports = true
      }
    }
  }
}
</script>

<style>
.justify-left-children > * {
  text-align: left;
}
.disabled-btn {
  opacity: 0.5;
}
.selected-reservation {
  background-color: cornsilk;
}
</style>
