<template>
  <canvas ref="chartCanvas" />
</template>

<script>
import Chart from 'chart.js'
export default {
  props: {
    usageData: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      id: 'usage-chart',
      chart: null
    }
  },
  methods: {
    updateChart () {
      const labels = this.categories.sort()
      const hours = []
      this.categories.sort().forEach(category => {
        const usage = this.usageData.find(element => element.usage_category === category)
        if (usage) {
          hours.push(usage.total_hours)
        } else {
          hours.push(0)
        }
      })
      this.chart.data.datasets[0].data = hours
      this.chart.data.labels = labels
      this.chart.update()
    }
  },
  mounted () {
    const ctx = this.$refs.chartCanvas
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      options: {
        cutoutPercentage: 50,
        title: {
          display: false
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            label: 'Usage by Hours',
            data: [],
            backgroundColor: [
              'rgba(200, 0, 0, 0.75)',
              'rgba(0, 200, 0, 0.75)',
              'rgba(0, 0, 200, 0.75)',
              'rgba(200, 100, 0, 0.75)',
              'rgba(200, 0, 100, 0.75)',
              'rgba(50, 100, 0, 0.75)',
              'rgba(0, 100, 100, 0.75)',
              'rgba(100, 0, 200, 0.75)',
              'rgba(0, 100, 200, 0.75)',
              'rgba(100, 100, 100, 0.75)',
              'rgba(0, 0, 0, 0.75)'
            ]
          }
        ]
      }
    })
    this.updateChart()
  }
}
</script>
