<template>
  <v-flex xs12 md6 lg6>
    <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="true"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
      v-if="dateScope === 'month'"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          outline
          v-model="selectedDate"
          :label="textLabel"
          prepend-icon="fa-calendar-alt"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="selectedDate"
        type="month"
        no-title
        scrollable
        color="primary"
        min="2015-09-01"
        :max="new Date().toISOString()"
        @input="dateMenu = false"
      />
    </v-menu>
    <v-select
      v-model="selectedDate"
      :items="
        Array.from(
          { length: new Date().getFullYear() - 2015 + 1 },
          (_, i) => new Date().getFullYear() - i
        )
      "
      prepend-icon="fa-calendar-alt"
      v-if="dateScope === 'year'"
      :label="textLabel"
    />
    <v-select
      v-model="selectedDate"
      :items="quarters"
      prepend-icon="fa-calendar-alt"
      v-if="dateScope === 'quarter'"
      :label="textLabel"
    />
  </v-flex>
</template>
<script>
export default {
  watch: {
    selectedDate (val) {
      this.$emit('update:selectedDate', val)
    }
  },
  props: {
    dateScope: {
      default: 'year',
      type: String
    },
    selectedDate: {
      default: '2023',
      type: String
    },
    textLabel: {
      default: 'Usage Period',
      type: String
    }
  },
  data () {
    const years = Array.from(
      { length: new Date().getFullYear() - 2015 + 1 },
      (_, i) => new Date().getFullYear() - i
    )
    const quarters = []
    years.forEach(year => {
      if (year === 2015) {
        quarters.push(`${year}-Q3`)
        quarters.push(`${year}-Q4`)
        return
      }
      if (year === new Date().getFullYear()) {
        for (let i = 0; i * 3 <= new Date().getMonth(); i++) {
          quarters.push(`${year}-Q${i + 1}`)
        }
        return
      }
      quarters.push(`${year}-Q1`)
      quarters.push(`${year}-Q2`)
      quarters.push(`${year}-Q3`)
      quarters.push(`${year}-Q4`)
    })
    return {
      quarters: quarters.sort().reverse(),
      years: years
    }
  },
  methods: {
    percentFormat (percent) {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2
      }).format(percent)
    }
  }
}
</script>
