<template>
  <v-layout>
    <v-flex xs12 md5>
      <v-card>
        <v-card-title class="headline primary white--text">
          Usage History
        </v-card-title>
        <v-container fluid grid-list-xs>
          <v-card-subtitle class="headline primary--text">
            Filters
          </v-card-subtitle>
          <v-layout>
            <v-flex xs6>
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="startDate"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  outline
                  v-model="startDate"
                  label="Start Date"
                  prepend-icon="fa-calendar-alt"
                  readonly
                />
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  color="primary"
                >
                  <v-spacer />
                  <v-btn flat color="primary" @click="startMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="$refs.startMenu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                ref="endMenu"
                v-model="endMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="endDate"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  outline
                  v-model="endDate"
                  label="End Date"
                  prepend-icon="fa-calendar-alt"
                  readonly
                />
                <v-date-picker
                  v-model="endDate"
                  no-title
                  scrollable
                  color="primary"
                  :min="new Date(this.startDate).toISOString()"
                >
                  <v-spacer />
                  <v-btn flat color="primary" @click="endMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="$refs.endMenu.save(endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-flex xs6>
            <v-checkbox
              v-model="filterIncomplete"
              label="Filter Incomplete and Tours"
              color="primary"
            />
          </v-flex>
        </v-container>
        <v-list two-line>
          <event
            v-for="(reservation, ind) in currentItems"
            :key="ind"
            :event="reservation"
            :class="{
              'selected-reservation': reservation.parent_id === previewEventId
            }"
            data-cy="usage-history"
          >
            <v-list-tile slot-scope="{ formatTitleDate, getStatus }">
              <v-list-tile-action>
                <v-tooltip top>
                  <status-light slot="activator" :status="getStatus" />
                  <span>{{ getStatus }}</span>
                </v-tooltip>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ reservation.reservation_title }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ formatTitleDate() }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-tooltip top v-if="reservation.reservation_desc === null">
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    size="2x"
                    class="error--text"
                    :icon="['far', 'exclamation-circle']"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Reservation info incomplete</span>
              </v-tooltip>
              <v-list-tile-action>
                <v-tooltip top>
                  <v-btn
                    flat
                    :disabled="reservation.parent_id === previewEventId"
                    slot="activator"
                    @click="setPreview(reservation)"
                    data-cy="preview-history"
                  >
                    <font-awesome-icon
                      :icon="['far', 'file-invoice']"
                      size="2x"
                    />
                  </v-btn>
                  <span>Preview</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
          </event>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="prevPage" :disabled="onFirstPage" color="primary">
            Prev
          </v-btn>
          <v-btn @click="nextPage" :disabled="onLastPage" color="primary">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md7>
      <reservation-preview
        :loading="loading"
        disablable
        :readonly="false"
        admin
      />
      <ReservationNotes
        title="Reservation Notes"
        :event-id="event_id"
        :loading="loading"
      />
    </v-flex>
    <v-dialog v-if="dialogAction !== null" v-model="isOpen" width="500">
      <v-card data-cy="workflow-dialog">
        <v-card-title class="primary headline white--text">
          {{ dialogAction.title }}
        </v-card-title>
        <v-progress-linear
          v-if="dialogNotesLoading"
          class="ma-0"
          indeterminate
        />
        <v-card-text v-else class="ml-4 mr-4 justify-left-children">
          <span v-if="selectedReservation.reservation_desc === null">
            <h3 style="color: darkred; display: inline; text-align: left">
              Warning:
            </h3>
            <h3 style="display: inline; text-align: left">
              This reservation is missing information
            </h3>
          </span>
          <span v-if="dialogNotes">
            <h2>Reservation Notes</h2>
            <p>{{ dialogNotes }}</p>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="isOpen = false" data-cy="workflow-dialog-dismiss">
            Go Back
          </v-btn>
          <v-btn
            @click="dialogAction.action"
            :color="dialogAction.actionColor"
            data-cy="workflow-dialog-action"
          >
            {{ dialogAction.actionText }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import api from '../api.js'
import Event from './Event'
import StatusLight from './StatusLight'
import ReservationPreview from './ReservationPreview.vue'
import ReservationNotes from './ReservationNotes'
import { format } from 'date-fns'
export default {
  components: {
    Event,
    StatusLight,
    ReservationNotes,
    ReservationPreview
  },
  props: {
    dateProp: {
      type: String,
      default: format(new Date(), 'YYYY-MM-DD')
    }
  },
  data () {
    return {
      reservationLoading: false,
      isOpen: false,
      selectedReservation: null,
      numPerPage: 10,
      currentPage: 1,
      previewEventId: null,
      today: new Date(),
      calendarStart: null,
      startMenu: null,
      endMenu: null,
      startDate: this.dateProp,
      endDate: this.dateProp,
      dialogNotes: null,
      dialogNotesLoading: false,
      dialogAction: null,
      filterIncomplete: false,
      showHistory: true,
      showBilling: false
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getUsageHistoryAction',
      'getReservationAction'
    ]),
    ...mapMutations('reservation', [
      'setCurrentReservation',
      'resetReservationForm'
    ]),
    setStartDate (e) {
      this.startDate = e.date
    },
    setEndDate (e) {
      this.endDate = e.date
    },
    pullDialogNotes (eventId) {
      this.dialogNotesLoading = true
      api
        .pullReservationNotes(eventId)
        .then(response => {
          this.dialogNotes = response.data.notes
          this.dialogNotesLoading = false
        })
        .catch(e => {
          if (e.response.status === 404) {
            // 404 -- No notes found
            this.dialogNotes = null
            this.dialogNotesLoading = false
          } else {
            this.logError(e, { root: true })
          }
        })
    },
    openDialog (reservation, dialogType) {
      this.selectedReservation = reservation
      this.pullDialogNotes(
        reservation.event_id
          ? reservation.event_id.substr(0, 26)
          : reservation.parent_id
      )
      this.isOpen = true
    },
    setPreview (reservation) {
      // OR allows this funtion to be used with different reservation event types
      this.previewEventId = reservation.event_id || reservation.parent_id
      if (reservation.reservation_desc !== null) {
        this.getReservationAction(
          reservation.original_event || reservation.parent_id
        )
      } else {
        // if filled in from gcal, then fill in what we can
        this.setCurrentReservation({
          addl_contacts: [],
          approved: reservation.approved,
          billing_address: '',
          billing_email: '',
          billing_info: '',
          billing_name: '',
          billing_phone: '',
          cancelled: false,
          company_name: '',
          data_collection: '',
          data_sharing: null,
          end_date: reservation.end_date,
          event_id: reservation.parent_id,
          facility_its: '',
          facility_prep: '',
          guest_office: false,
          issues: null,
          media: [],
          original_event: null,
          partial_reservation: '',
          pending: false,
          primary_email: reservation.primary_email,
          primary_name: '',
          primary_phone: '',
          rate_override: null,
          reservation_desc: '',
          reservation_equipment: [],
          reservation_key: -1,
          reservation_title: reservation.reservation_title,
          safety_plan_key: -1,
          safety_plan_media: null,
          safety_plan_state: 'pending_mcity',
          start_date: reservation.start_date,
          timeslot: null,
          vehicles: []
        })
      }
    },
    nextPage () {
      if (!this.onLastPage) this.currentPage++
    },
    prevPage () {
      if (!this.onFirstPage) this.currentPage--
    }
  },
  watch: {
    startDate: function (val) {
      if (typeof this.startDate !== 'undefined' && typeof this.endDate !== 'undefined') {
        this.getUsageHistoryAction({ startDate: this.startDate, endDate: this.endDate })
      }
    },
    endDate: function (val) {
      if (typeof this.startDate !== 'undefined' && typeof this.endDate !== 'undefined') {
        this.getUsageHistoryAction({ startDate: this.startDate, endDate: this.endDate })
      }
    }
  },
  computed: {
    ...mapGetters('reservation', ['usageHistory']),
    ...mapState('reservation', {
      reservation_not_loaded: state => state.currentReservation === null,
      event_id: state =>
        state.currentReservation
          ? state.currentReservation.event_id
            ? state.currentReservation.event_id
            : state.currentReservation.parent_id
          : null
    }),
    loading () {
      // Substring removes recurring event instance information
      return this.previewEventId
        ? this.previewEventId.substr(0, 26) !== this.event_id.substr(0, 26)
        : false
    },
    filteredUsageHistory () {
      if (this.filterIncomplete) {
        return this.usageHistory.filter(
          reservation => reservation.reservation_desc !== null
        )
      } else {
        return this.usageHistory
      }
    },
    currentItems () {
      const startIndex = (this.currentPage - 1) * this.numPerPage
      return this.filteredUsageHistory.slice(
        startIndex,
        startIndex + this.numPerPage
      )
    },
    onFirstPage () {
      return this.currentPage === 1
    },
    onLastPage () {
      return (
        this.currentPage >= this.filteredUsageHistory.length / this.numPerPage
      )
    }
  },
  created () {
    const date = new Date()
    const y = date.getFullYear()
    const m = date.getMonth()
    this.startDate = format(new Date(y, m, 1), 'YYYY-MM-DD')
    this.endDate = format(new Date(y, m + 1, 0), 'YYYY-MM-DD')
    this.getUsageHistoryAction({ startDate: this.startDate, endDate: this.endDate })
    this.resetReservationForm()
  }
}
</script>

<style>
.justify-left-children > * {
  text-align: left;
}
.disabled-btn {
  opacity: 0.5;
}
.selected-reservation {
  background-color: cornsilk;
}
</style>
