<template>
  <v-layout>
    <v-flex xs12 md12 lg12>
      <v-card>
        <v-card-title class="headline primary white--text">
          Usage Reports
        </v-card-title>
        <template>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading"
          />
        </template>
        <v-layout row wrap align-space-around justify-center fill-height ma-2>
          <v-layout
            column
            wrap
            align-space-around
            justify-center
            fill-height
            ma-2
          />
          <v-layout
            column
            wrap
            align-space-around
            justify-center
            fill-height
            ma-2
          >
            <v-flex align-space-around justify-center>
              <v-radio-group row v-model="dateScope">
                <v-radio label="Usage by Month" value="month" color="primary" />
                <v-radio label="Usage by Quarter" value="quarter" color="primary" />
                <v-radio label="Usage by Year" value="year" color="primary" />
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout
            column
            wrap
            align-space-around
            justify-center
            fill-height
            ma-2
          >
            <v-flex align-space-around justify-center>
              <v-checkbox
                v-model="enableComparison"
                label="Side-by-Side Comparison"
                color="primary"
              />
            </v-flex>
          </v-layout>
          <v-layout
            column
            wrap
            align-space-around
            justify-center
            fill-height
            ma-2
          >
            <v-flex align-space-around justify-center>
              <v-checkbox
                v-model="enableDateRange"
                label="Date Range"
                color="primary"
              />
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md10 lg3>
            <v-text-field
              v-model="companyName"
              label="Filter by Company Name"
            />
            <v-btn
              color="primary"
              @click="updateUsageData"
            >
              Filter
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row wrap justify-space-around>
        <UsageDatePicker :date-scope="dateScope" :selected-date.sync="selectedDate" text-label="Usage Period" />
        <UsageDatePicker v-if="enableComparison" :date-scope="dateScope" :selected-date.sync="comparisonDate" text-label="Comparison Usage Period" />
      </v-layout>
      <v-layout row wrap justify-space-around v-if="enableDateRange">
        <UsageDatePicker :date-scope="dateScope" :selected-date.sync="selectedDateEnd" text-label="Usage Period End" />
        <UsageDatePicker v-if="enableComparison" :date-scope="dateScope" :selected-date.sync="comparisonDateEnd" text-label="Comparison Usage Period End" />
      </v-layout>
      <template v-if="loading">
        <v-progress-circular :size="100" :width="7" color="primary" indeterminate />
        <div>Gathering Usage Data. This May Take Some Time...</div>
      </template>
      <template v-else>
        <template v-if="usageSummary.length > 1">
          <v-layout row wrap justify-space-around>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison && !enableDateRange">
                Usage by Hours: {{ selectedDate }}
              </h3>
              <h3 v-if="enableComparison && enableDateRange">
                Usage by Hours: {{ selectedDate }} - {{ selectedDateEnd }}
              </h3>
              <UsageChart :usage-data="usageSummary" :categories="categories" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison && !enableDateRange">
                Usage by Hours: {{ comparisonDate }}
              </h3>
              <h3 v-if="enableComparison && enableDateRange">
                Usage by Hours: {{ comparisonDate }} - {{ comparisonDateEnd }}
              </h3>
              <UsageChart :usage-data="usageSummaryComparison" :categories="categories" />
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12 lg12>
              <h2>Usage Statistics</h2>
            </v-flex>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison">
                {{ selectedDate }}
              </h3>
              <UsageSummaryTable :summary-data="usageSummary" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison">
                {{ comparisonDate }}
              </h3>
              <UsageSummaryTable :summary-data="usageSummaryComparison" />
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12 lg12>
              <h2>Tour Breakdown</h2>
            </v-flex>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison">
                {{ selectedDate }}
              </h3>
              <UsageHoursTable :event-data="tourBreakdown" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison">
                {{ comparisonDate }}
              </h3>
              <UsageHoursTable :event-data="tourBreakdownComparison" />
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12 lg12>
              <h2>Vehicle Breakdown</h2>
            </v-flex>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison">
                {{ selectedDate }}
              </h3>
              <UsageHoursTable :event-data="vehicleHours" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison">
                {{ comparisonDate }}
              </h3>
              <UsageHoursTable :event-data="vehicleHoursComparison" />
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12 lg12>
              <h2>Equipment Breakdown</h2>
            </v-flex>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison">
                {{ selectedDate }}
              </h3>
              <UsageHoursTable :event-data="equipmentHours" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison">
                {{ comparisonDate }}
              </h3>
              <UsageHoursTable :event-data="equipmentHoursComparison" />
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12 lg12>
              <h2>Technician Breakdown</h2>
            </v-flex>
            <v-flex :class="enableComparison ? 'xs12 md6 lg6' : 'xs12 md10 lg8'">
              <h3 v-if="enableComparison">
                {{ selectedDate }}
              </h3>
              <UsageHoursTable :event-data="techTimeHours" />
            </v-flex>
            <v-flex xs12 md6 lg6 v-if="enableComparison">
              <h3 v-if="enableComparison">
                {{ comparisonDate }}
              </h3>
              <UsageHoursTable :event-data="techTimeHoursComparison" />
            </v-flex>
          </v-layout>
        </template>
        <div v-else>
          No Report Data Available
        </div>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
// eslint-disable no-unused-expressions

import { format } from 'date-fns'
import UsageChart from './UsageChart.vue'
import UsageSummaryTable from './UsageSummaryTable.vue'
import UsageHoursTable from './UsageHoursTable.vue'
import UsageDatePicker from './UsageDatePicker.vue'
import api from '../api'
import axios from 'axios'
export default {
  components: { UsageChart, UsageSummaryTable, UsageHoursTable, UsageDatePicker },
  data () {
    return {
      enableComparison: false,
      enableDateRange: false,
      selectedDate: '',
      comparisonDate: '',
      selectedDateEnd: '',
      comparisonDateEnd: '',
      dateScope: 'month',
      loading: false,
      usageSummary: [],
      usageSummaryComparison: [],
      tourBreakdown: [],
      tourBreakdownComparison: [],
      vehicleHours: [],
      vehicleHoursComparison: [],
      equipmentHours: [],
      equipmentHoursComparison: [],
      techTimeHours: [],
      techTimeHoursComparison: [],
      companyName: '',
      getUsageReportDataCancelToken: null,
      compareGetUsageReportDataCancelToken: null,
      getUsageReportDataRangeCancelToken: null,
      compareGetUsageReportDataRangeCancelToken: null,
      getUsageReportDataInProgress: false,
      getUsageReportDataRangeInProgress: false,
      getCompareUsageReportDataRangeInProgress: false,
      getCompareUsageReportDataInProgress: false
    }
  },
  methods: {
    dateFormat (date) {
      return format(date, 'YYYY-MM-DD')
    },
    updateUsageData (callType) {
      this.getUsageReportDataCancelToken?.cancel()
      this.compareGetUsageReportDataCancelToken?.cancel()
      this.getUsageReportDataRangeCancelToken?.cancel()
      this.compareGetUsageReportDataRangeCancelToken?.cancel()
      this.getUsageReportDataCancelToken = null
      this.compareGetUsageReportDataCancelToken = null
      this.getUsageReportDataRangeCancelToken = null
      this.compareGetUsageReportDataRangeCancelToken = null

      this.loading = true
      if (this.enableDateRange) {
        const getUsageReportDataRangeCancelTokenSource = axios.CancelToken.source()
        this.getUsageReportDataRangeCancelToken = getUsageReportDataRangeCancelTokenSource
        api.getUsageReportDataRange(this.selectedDate, this.selectedDateEnd, this.companyName, getUsageReportDataRangeCancelTokenSource.token).then(response => {
          this.getUsageReportDataRangeCancelToken = null
          this.handleUsageData(response, false)
        }).catch((err) => {
          err.response ? console.error(err.response) : console.log('Get Usage Reports Request Canceled')
        })
        if (this.enableComparison) {
          const compareGetUsageReportDataRangeCancelTokenSource = axios.CancelToken.source()
          this.compareGetUsageReportDataRangeCancelToken = compareGetUsageReportDataRangeCancelTokenSource
          api.getUsageReportDataRange(this.comparisonDate, this.comparisonDateEnd, this.companyName, compareGetUsageReportDataRangeCancelTokenSource.token).then(response => {
            this.compareGetUsageReportDataRangeCancelToken = null
            this.handleUsageData(response, true)
          }).catch((err) => {
            err.response ? console.error(err.response) : console.log('Get Usage Reports Request Canceled')
          })
        }
        return
      }
      const getUsageReportDataCancelTokenSource = axios.CancelToken.source()
      this.getUsageReportDataCancelToken = getUsageReportDataCancelTokenSource
      api.getUsageReportData(this.selectedDate, this.companyName, getUsageReportDataCancelTokenSource.token).then(response => {
        this.getUsageReportDataCancelToken = null
        this.handleUsageData(response, false)
      }).catch((err) => {
        err.response ? console.error(err.response) : console.log('Get Usage Reports Request Canceled')
      })
      if (this.enableComparison) {
        const compareGetUsageReportDataCancelTokenSource = axios.CancelToken.source()
        this.compareGetUsageReportDataCancelToken = compareGetUsageReportDataCancelTokenSource
        api.getUsageReportData(this.comparisonDate, this.companyName, compareGetUsageReportDataCancelTokenSource.token).then(response => {
          this.compareGetUsageReportDataCancelToken = null
          this.handleUsageData(response, true)
        }).catch((err) => {
          err.response ? console.error(err.response) : console.log('Get Usage Reports Request Canceled')
        })
      }
    },
    handleUsageData (response, comparison = false) {
      const usageData = []
      const tourData = []
      const vehicleData = []
      const equipmentData = []
      const techTimeData = []

      response.data.forEach(reservation => {
        if (reservation.usage_type === 'Reservation') {
          usageData.push(reservation)
        } else if (reservation.usage_type === 'Tour') {
          tourData.push(reservation)
        } else if (reservation.usage_type === 'Vehicle Rental') {
          vehicleData.push(reservation)
        } else if (reservation.usage_type === 'Equipment Rental') {
          equipmentData.push(reservation)
        } else if (reservation.usage_type === 'Technician') {
          techTimeData.push(reservation)
        }
      })
      if (comparison) {
        this.usageSummaryComparison = this.handleUsageSummary(usageData)
        this.tourBreakdownComparison = this.handleBreakdown(tourData)
        this.vehicleHoursComparison = this.handleBreakdown(vehicleData)
        this.equipmentHoursComparison = this.handleBreakdown(equipmentData)
        this.techTimeHoursComparison = this.handleBreakdown(techTimeData)
      } else {
        this.usageSummary = this.handleUsageSummary(usageData)
        this.tourBreakdown = this.handleBreakdown(tourData)
        this.vehicleHours = this.handleBreakdown(vehicleData)
        this.equipmentHours = this.handleBreakdown(equipmentData)
        this.techTimeHours = this.handleBreakdown(techTimeData)
      }
      if (comparison || !this.enableComparison) {
        this.loading = false
      }
    },
    handleBreakdown (dataIn) {
      const hours = []
      let totalHours = 0.0
      dataIn.forEach(event => {
        totalHours += event.usage_hours
      })
      dataIn.forEach(event => {
        if (event.usage_hours !== 0) {
          hours.push({
            event_type: event.usage_category,
            event_hours: event.usage_hours,
            event_percent:
              totalHours !== 0.0 ? (event.usage_hours / totalHours) : 0.0,
            reservation_details: event.reservation_details
          })
        }
      })
      hours.push({
        event_type: 'Total',
        event_hours: totalHours,
        event_percent: 1,
        reservation_details: []
      })
      return hours
    },
    handleUsageSummary (usageData) {
      const usageSummary = []
      let usageHoursTotal = 0.0
      let usageInvoiceTotal = 0.0
      usageData.forEach(usageEntry => {
        usageHoursTotal += usageEntry.usage_hours
        usageInvoiceTotal += usageEntry.usage_invoice
      })
      usageData.forEach(usageEntry => {
        if (!(usageEntry.usage_invoice === 0.0 && usageEntry.usage_hours === 0.0)) {
          usageSummary.push({
            usage_category: usageEntry.usage_category,
            invoice_total: usageEntry.usage_invoice,
            invoice_percent:
              usageInvoiceTotal !== 0.0 ? (usageEntry.usage_invoice / usageInvoiceTotal) : 0.0,
            total_hours: usageEntry.usage_hours,
            total_percent: usageHoursTotal !== 0.0 ? (usageEntry.usage_hours / usageHoursTotal) : 0.0,
            reservation_details: usageEntry.reservation_details
          })
        }
      })
      usageSummary.push({
        usage_category: 'Total',
        invoice_total: usageInvoiceTotal,
        invoice_percent: 1,
        total_hours: usageHoursTotal,
        total_percent: 1
      })
      return usageSummary
    }
  },
  watch: {
    dateScope: function (newScope, oldScope) {
      switch (newScope) {
        case 'year':
          this.selectedDate = String(this.selectedDate).slice(0, 4)
          this.comparisonDate = String(this.comparisonDate).slice(0, 4)
          this.selectedDateEnd = String(this.selectedDateEnd).slice(0, 4)
          this.comparisonDateEnd = String(this.comparisonDateEnd).slice(0, 4)
          break
        case 'month':
          this.selectedDate = String(this.selectedDate).slice(0, 4) + '-01'
          this.comparisonDate = String(this.comparisonDate).slice(0, 4) + '-01'
          this.selectedDateEnd = String(this.selectedDateEnd).slice(0, 4) + '-01'
          this.comparisonDateEnd = String(this.comparisonDateEnd).slice(0, 4) + '-01'
          break
        case 'quarter':
          this.selectedDate = String(this.selectedDate).slice(0, 4) + '-Q1'
          this.comparisonDate = String(this.comparisonDate).slice(0, 4) + '-Q1'
          this.selectedDateEnd = String(this.selectedDateEnd).slice(0, 4) + '-Q1'
          this.comparisonDateEnd = String(this.comparisonDateEnd).slice(0, 4) + '-Q1'
          break
      }
    },
    selectedDate: function (val, oldVal) {
      if (oldVal !== '') {
        this.updateUsageData('select_start_1')
      }
    },
    comparisonDate: function (val, oldVal) {
      if (oldVal !== '') {
        this.updateUsageData('compare')
      }
    },
    selectedDateEnd: function (val, oldVal) {
      if (oldVal !== '') {
        this.updateUsageData('selected_end')
      }
    },
    comparisonDateEnd: function (val, oldVal) {
      if (oldVal !== '') {
        this.updateUsageData('compare_end')
      }
    },
    enableComparison: function (val) {
      this.updateUsageData('enable_compare')
    },
    enableDateRange: function (val) {
      this.updateUsageData('date_range')
    }
  },
  computed: {
    categories () {
      const categories = []
      this.usageSummary.forEach(reservation => {
        if (!categories.includes(reservation.usage_category) && reservation.usage_category !== 'Total') {
          categories.push(reservation.usage_category)
        }
      })
      if (this.enableComparison) {
        this.usageSummaryComparison.forEach(reservation => {
          if (!categories.includes(reservation.usage_category) && reservation.usage_category !== 'Total') {
            categories.push(reservation.usage_category)
          }
        })
      }
      return categories
    }
  },
  created () {
    const today = new Date(Date.now())
    const month = today.getMonth()
    const year = today.getFullYear()
    const getDate = (year, month) => {
      if (month < 2) {
        return `${year - 1}-${month + 11}`
      } else {
        if (month < 10) {
          return `${year}-0${month - 1}`
        }
        return `${year}-${month - 1}`
      }
    }
    this.selectedDate = getDate(year, month)
    this.comparisonDate = getDate(year - 1, month)
    this.selectedDateEnd = getDate(year, month + 1)
    this.comparisonDateEnd = getDate(year - 1, month + 1)
    this.updateUsageData('create')
    this.firstPageLoadComplete = true
  }
}
</script>
