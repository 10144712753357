<template>
  <v-layout>
    <v-flex xs12 md12>
      <v-card>
        <v-card-title class="headline primary white--text">
          Billing and Reports Generation
        </v-card-title>
        <v-container fluid grid-list-xs>
          <v-layout>
            <v-flex xs6>
              <v-menu
                ref="monthMenu"
                v-model="monthMenu"
                :close-on-content-click="true"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outline
                    v-model="selectedMonth"
                    label="Billing Month"
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="selectedMonth"
                  type="month"
                  no-title
                  scrollable
                  color="primary"
                  min="2022-01-01"
                  :max="new Date().toISOString()"
                  @input="fetchBillingItems(selectedMonth)"
                />
              </v-menu>
            </v-flex>
            <v-flex xs3>
              <v-btn
                color="primary"
                @click="initBilling(selectedMonth)"
                data-cy="init-billing-items"
              >
                Generate Billing Data
              </v-btn>
            </v-flex>
            <v-flex xs3>
              <v-btn
                color="primary"
                @click="dumpBillingData(selectedMonth)"
                data-cy="dump-billing-items"
              >
                Dump to Spreadsheet
              </v-btn>
            </v-flex>
            <v-flex xs3>
              <v-btn
                color="primary"
                @click="confirmFinalizeDialog = true"
                data-cy="get-billing-items"
              >
                Finalize Billing Report
              </v-btn>
            </v-flex>
          </v-layout>
          <v-alert :value="true" color="info" v-if="reportGenerated">
            Your reports are being generated and will be accessible in
            <a href="https://transmission.um.city">Transmission</a> shortly.
          </v-alert>
          <v-data-table
            dense
            v-model="selected"
            :headers="event_headers"
            :items="events"
            :items-per-page="-1"
            :loading="loading"
            item-key="event_id"
            expand="true"
            hide-actions
            select-all
            class="elevation-1"
            ref="event_table"
          >
            <template v-slot:items="event_props">
              <tr @click="event_props.expanded = !event_props.expanded">
                <td>
                  <v-checkbox
                    v-model="event_props.selected"
                    primary
                    hide-details
                  />
                </td>
                <!-- Service Date -->
                <td>{{ dateFormat(event_props.item.start_date) }}</td>
                <!-- Title -->
                <td>{{ event_props.item.reservation_title }}</td>
                <!-- Description -->
                <td>{{ event_props.item.reservation_desc }}</td>
                <!-- Services -->
                <td>
                  <div
                    v-for="item in event_props.item.services_list"
                    :key="item"
                  >
                    {{ item }}
                  </div>
                </td>
                <!-- Total Charges -->
                <td>${{ event_props.item.total_charges }}</td>
                <!-- Hours -->
                <td>{{ event_props.item.hours }}</td>
                <!-- Add Line Item -->
                <td justify-center layout px-0>
                  <div @click.stop="fillNewLineItem(event_props.item)">
                    <font-awesome-icon
                      class="mr-2"
                      size="lg"
                      :icon="['fa', 'plus']"
                      style="margin: 7px"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:expand="event_props">
              <v-flex d-inline-flex>
                <v-data-table
                  dense
                  :headers="billing_headers"
                  :items="grouped_line_items[event_props.item.event_id]"
                  :items-per-page="-1"
                  :loading="loading"
                  hide-actions
                  item-key="service"
                  class="elevation-1 sub-table"
                >
                  <template v-slot:items="props">
                    <!-- Service -->
                    <td>{{ props.item.service }}</td>
                    <!-- Service Quantity -->
                    <td>{{ props.item.service_qty }}</td>
                    <!-- Rate -->
                    <td v-if="props.item.service.includes('Override')">
                      {{ "$" + props.item.rate_override }}
                    </td>
                    <!-- Rate -->
                    <td v-else>
                      {{ "$" + props.item.service_rate }}
                    </td>
                    <!-- Total Charge -->
                    <td v-if="props.item.service.includes('Override')">
                      {{
                        "$" +
                          String(
                            parseFloat(props.item.rate_override) *
                              parseFloat(props.item.service_qty)
                          )
                      }}
                    </td>
                    <!-- Total Charge -->
                    <td v-else>
                      {{
                        "$" +
                          String(
                            totalFormat(props.item.service_rate * props.item.service_qty)
                          )
                      }}
                    </td>
                    <!-- Shortcode -->
                    <td>{{ props.item.shortcode }}</td>
                    <!-- Invoice -->
                    <td>{{ props.item.invoice }}</td>
                    <!-- Tour -->
                    <td>
                      <v-checkbox
                        v-model="props.item.is_tour"
                        primary
                        hide-details
                        disabled
                      />
                    </td>
                    <!-- Description -->
                    <td>{{ props.item.description }}</td>
                    <td justify-center layout px-0>
                      <div @click="editLineItem(props.item)">
                        <font-awesome-icon
                          class="mr-2"
                          size="lg"
                          :icon="['fa', 'pen']"
                          style="margin: 5px"
                        />
                      </div>
                      <div
                        @click="
                          (showConfirmDeleteDialog = true),
                          (lineItemToDelete = props.item)
                        "
                      >
                        <v-dialog width="fit-content" v-model="showConfirmDeleteDialog" persistent>
                          <v-card>
                            <v-card-title class="headline">
                              Are you sure you want to delete this line item?
                            </v-card-title>
                            <v-card-text>
                              <v-layout
                                align-center
                                justify-center
                                row
                                fill-height
                              >
                                <table class="confirm_delete_table">
                                  <thead>
                                    <tr>
                                      <th class="confirm_delete_td_th">
                                        Service Date
                                      </th>
                                      <template
                                        v-for="header_title in billing_headers"
                                      >
                                        <th
                                          :key="header_title"
                                          class="confirm_delete_td_th"
                                          scope="col"
                                          v-if="header_title.text !== 'Actions'"
                                        >
                                          {{ header_title.text }}
                                        </th>
                                      </template>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <td>
                                      {{
                                        dateFormat(event_props.item.start_date)
                                      }}
                                    </td>
                                    <td class="confirm_delete_td_th">
                                      {{ lineItemToDelete.service }}
                                    </td>
                                    <!-- Service Quantity -->
                                    <td class="confirm_delete_td_th">
                                      {{ lineItemToDelete.service_qty }}
                                    </td>
                                    <!-- Rate -->
                                    <td
                                      class="confirm_delete_td_th"
                                      v-if="
                                        props.item.service.includes('Override')
                                      "
                                    >
                                      {{ "$" + lineItemToDelete.rate_override }}
                                    </td>
                                    <!-- Rate -->
                                    <td class="confirm_delete_td_th" v-else>
                                      {{ "$" + lineItemToDelete.service_rate }}
                                    </td>
                                    <!-- Total Charge -->
                                    <td
                                      class="confirm_delete_td_th"
                                      v-if="
                                        props.item.service.includes('Override')
                                      "
                                    >
                                      {{
                                        "$" +
                                          String(
                                            parseFloat(
                                              lineItemToDelete.rate_override
                                            ) *
                                              parseFloat(
                                                lineItemToDelete.service_qty
                                              )
                                          )
                                      }}
                                    </td>
                                    <!-- Total Charge -->
                                    <td class="confirm_delete_td_th" v-else>
                                      {{
                                        "$" +
                                          String(
                                            parseFloat(
                                              lineItemToDelete.service_rate
                                            ) *
                                              parseFloat(
                                                lineItemToDelete.service_qty
                                              )
                                          )
                                      }}
                                    </td>
                                    <!-- Shortcode -->
                                    <td class="confirm_delete_td_th">
                                      {{ lineItemToDelete.shortcode || "N/A" }}
                                    </td>
                                    <!-- Invoice -->
                                    <td class="confirm_delete_td_th">
                                      {{ lineItemToDelete.invoice || "N/A" }}
                                    </td>
                                    <!-- Tour -->
                                    <td class="confirm_delete_td_th">
                                      <v-checkbox
                                        v-model="lineItemToDelete.is_tour"
                                        primary
                                        hide-details
                                        disabled
                                      />
                                    </td>
                                    <!-- Description -->
                                    <td class="confirm_delete_td_th">
                                      {{ lineItemToDelete.description }}
                                    </td>
                                  </tbody>
                                </table>
                              </v-layout>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="primary darken-1"
                                flat="flat"
                                @click="showConfirmDeleteDialog = false"
                              >
                                Cancel
                              </v-btn>

                              <v-btn
                                color="red darken-1"
                                flat="flat"
                                @click="deleteLineItem(lineItemToDelete)"
                              >
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <font-awesome-icon
                          class="mr-2"
                          size="lg"
                          :icon="['fa', 'trash']"
                          style="margin: 5px"
                        />
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info">
                Nothing to see here. Select the Month you're interested in, then
                click "View/Edit Billing Data".
              </v-alert>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-flex>
    <v-dialog v-model="confirmFinalizeDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirm Finalization for {{ selectedMonth }}?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click="confirmFinalizeDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click="finalizeBillingItems(selectedMonth)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout v-if="formTitle === 'Edit Line Item'" wrap>
              <v-flex xs12 sm12 md12>
                <v-combobox
                  v-model="tempEditedLineItem.service"
                  :items="services"
                  label="Service"
                  @change="updateRate"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="tempEditedLineItem.service_qty"
                  label="Service Quantity"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="tempEditedLineItem.service_rate"
                  label="Service Rate"
                  @change="rateChanged"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="tempEditedLineItem.shortcode"
                  label="Short Code"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="tempEditedLineItem.invoice"
                  label="Invoice"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-checkbox v-model="tempEditedLineItem.is_tour" label="Tour" />
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  v-model="tempEditedLineItem.description"
                  label="Description"
                />
              </v-flex>
            </v-layout>
            <v-layout v-else wrap>
              <v-flex xs12 sm12 md12>
                <v-combobox
                  v-model="newLineItem.service"
                  :items="services"
                  label="Service"
                  @change="updateRate"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="newLineItem.service_qty"
                  label="Service Quantity"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="newLineItem.service_rate"
                  label="Service Rate"
                  @change="rateChanged"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="newLineItem.shortcode"
                  label="Short Code"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="newLineItem.invoice"
                  label="Invoice"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-checkbox v-model="newLineItem.is_tour" label="Tour" />
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  v-model="newLineItem.description"
                  label="Description"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" flat @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" flat @click="saveLineItem()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { format } from 'date-fns'
import api from '../api'
export default {
  data () {
    return {
      selected: [],
      formTitle: '',
      monthMenu: null,
      selectedMonth: format(new Date(), 'YYYY-MM'),
      line_items: [],
      grouped_line_items: {},
      events: [],
      loading: false,
      editedIndex: -1,
      reportGenerated: false,
      editedLineItem: {
        billing_item_key: -1,
        event_id: '',
        event: {},
        service: '20XX-Other (No Charge)',
        service_qty: 1,
        service_rate: 0,
        shortcode: '',
        invoice: '',
        is_tour: false,
        rate_override: 0,
        description: ''
      },
      newLineItem: {
        billing_item_key: -1,
        event_id: '',
        event: {},
        service: '20XX-Other (No Charge)',
        service_qty: 1,
        service_rate: 0,
        shortcode: '',
        invoice: '',
        is_tour: false,
        rate_override: 0,
        description: ''
      },
      tempEditedLineItem: {

      },
      editDialog: false,
      showConfirmDeleteDialog: false,
      lineItemToDelete: {},
      confirmFinalizeDialog: false,
      rates: [],
      services: [],
      event_headers: [
        {
          text: 'Service Date',
          value: 'start_date',
          align: 'start',
          sortable: true
        },
        {
          text: 'Title',
          value: 'reservation_title',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Description',
          value: 'reservation_desc',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Billed Services',
          value: 'services_list',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Total Charges',
          value: 'total_charges',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Hours',
          value: 'hours',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false
        }
      ],
      billing_headers: [
        {
          text: 'Service',
          value: 'service',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Hours/Amount',
          value: 'hours',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Rate',
          value: 'rate',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Total',
          value: 'total',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Shortcode',
          value: 'shortcode',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Invoice',
          value: 'invoice',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Tour',
          value: 'is_tour',
          sortable: true,
          align: 'start'
        },
        {
          text: 'Description',
          value: 'description',
          align: 'start',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'start'
        }
      ]
    }
  },
  methods: {
    dateFormat (date) {
      return format(date, 'YYYY-MM-DD')
    },
    initBilling (date) {
      this.loading = true
      api.initializeBilling(date).then(response => {
        this.update_items_and_events(response.data.items)
        this.loading = false
      })
    },
    totalFormat (total) {
      const formattedResult = total.toFixed(2)
      if (formattedResult.includes('.00')) {
        return parseInt(formattedResult, 10)
      }

      return formattedResult
    },
    fetchBillingItems (date) {
      this.monthMenu = false
      this.loading = true
      this.getRates()
      api.getBillingItems(date).then(response => {
        if (response.data.items.length === 0) {
          this.line_items = []
          this.events = []
          this.grouped_line_items = []
          this.selected = []
          this.loading = false
        } else {
          this.update_items_and_events(response.data.items)
          this.loading = false
        }
      })
    },
    dumpBillingData (date) {
      this.loading = true
      api.dumpBillingData(date).then(async response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `${date}-billing.xlsx`) // or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        this.loading = false
      })
    },
    finalizeBillingItems (date) {
      this.loading = true
      this.confirmFinalizeDialog = false
      api.finalizeBillingItems(date).then(response => {
        this.reportGenerated = true
        this.loading = false
      })
    },
    update_items_and_events (items) {
      this.line_items = items
      this.events = []
      this.grouped_line_items = []
      const eventIDs = []
      items.forEach(item => {
        if (eventIDs.indexOf(item.event.event_id) === -1) {
          eventIDs.push(item.event.event_id)
          this.events.push(item.event)
          this.grouped_line_items[item.event.event_id] = [item]
        } else {
          this.grouped_line_items[item.event.event_id].push(item)
        }
      })
      this.events.forEach(event => {
        event.services_list = this.servicesList(event.event_id)
        event.total_charges = 0
        this.grouped_line_items[event.event_id].forEach(lineItem => {
          if (lineItem.service.includes('Override')) {
            event.total_charges +=
              lineItem.rate_override * lineItem.service_qty
          } else {
            event.total_charges +=
              this.fetchRateFromService(lineItem.service) *
              lineItem.service_qty
          }
        })
        event.hours = Math.max.apply(
          null,
          Array.from(
            {
              length: this.grouped_line_items[event.event_id].length
            },
            (_, i) =>
              parseFloat(this.grouped_line_items[event.event_id][i].service_qty)
          )
        )
      })
      this.selected = [...this.events]
    },
    fillNewLineItem (event) {
      this.formTitle = 'New Line Item'
      this.newLineItem = {
        billing_item_key: -1,
        event_id: event.event_id,
        event: event,
        service: '20XX-Other (No Charge)',
        service_qty: 1,
        service_rate: 0,
        shortcode: '',
        invoice: '',
        rate_override: 0,
        is_tour: false,
        description: 'Manually created line item.'
      }
      this.editDialog = true
    },
    editLineItem (item) {
      this.formTitle = 'Edit Line Item'
      this.editedLineItem = this.line_items.filter(
        i => i.billing_item_key === item.billing_item_key
      )[0]
      this.tempEditedLineItem = Object.assign({}, this.editedLineItem)
      this.editDialog = true
    },
    deleteLineItem (item) {
      api.deleteBillingItem(item.billing_item_key).then(() => {
        // this.fetchBillingItems(this.selectedMonth)
        this.loading = true
        let newItems = [...this.line_items]
        newItems = newItems.filter(iterItem => {
          return iterItem.billing_item_key !== item.billing_item_key
        })
        this.update_items_and_events(newItems)
        this.loading = false
        this.showConfirmDeleteDialog = false
      })
    },
    saveLineItem () {
      if (this.formTitle === 'New Line Item') {
        api.createBillingLineItem(this.newLineItem).then((res) => {
          this.newLineItem.billing_item_key = res.data.billing_item_key
          this.loading = true
          const newItems = [...this.line_items]
          newItems.push(JSON.parse(JSON.stringify(this.newLineItem)))
          this.loading = false
          this.update_items_and_events(newItems)
        })
      } else {
        api.patchBillingLineItem(this.tempEditedLineItem)
        this.fetchBillingItems(this.selectedMonth)
      }
      this.closeDialog()
    },
    closeDialog () {
      this.editedLineItem = this.tempEditedLineItem
      this.editDialog = false
    },
    updateRate () {
      if (this.formTitle === 'New Line Item') {
        this.newLineItem.service_rate = this.fetchRateFromService(this.newLineItem.service)
      } else {
        this.tempEditedLineItem.service_rate = this.fetchRateFromService(this.tempEditedLineItem.service)
      }
    },
    fetchRateFromService (service) {
      try {
        return this.rates.filter(rate => rate.rate_cd === service)[0]
          .rate_amount
      } catch {
        console.log('Rate not found for service: ' + service)
        return 0
      }
    },
    rateChanged () {
      if (this.formTitle === 'Edit Line Item') {
        const overrideService = this.tempEditedLineItem.service
          .replace(/\((.+)\)/, '(Override)')
          .replace(/20../, '20XX')
        this.tempEditedLineItem.service = '20XX-Other (Override)'
        this.rates.forEach(rate => {
          if (rate.rate_cd === overrideService) {
            this.tempEditedLineItem.service = overrideService
          }
        })
        this.tempEditedLineItem.rate_override = this.tempEditedLineItem.service_rate
      } else {
        const overrideService = this.newLineItem.service
          .replace(/\((.+)\)/, '(Override)')
          .replace(/20../, '20XX')
        this.newLineItem.service = '20XX-Other (Override)'
        this.rates.forEach(rate => {
          if (rate.rate_cd === overrideService) {
            this.newLineItem.service = overrideService
          }
        })
        this.newLineItem.rate_override = this.newLineItem.service_rate
      }
    },
    servicesList (eventID) {
      const servicesList = []
      this.grouped_line_items[eventID].forEach(item => {
        servicesList.push(item.service)
      })
      return servicesList
    },
    getRates () {
      api.getRates(this.selectedMonth).then(response => {
        this.rates = response.data
        this.services = []
        this.rates.forEach(rate => {
          this.services.push(rate.rate_cd)
        })
      })
    }
  },
  computed: {
    total_charges () {
      const totalCharges = {}
      this.events.forEach(event => {
        let total = 0
        this.grouped_line_items[event.event_id].forEach(lineItem => {
          if (lineItem.service.includes('Override')) {
            total += lineItem.rate_override * lineItem.service_qty
          } else {
            total +=
              this.fetchRateFromService(lineItem.service) *
              lineItem.service_qty
          }
        })
        totalCharges[event.event_id] = total
      })
      return totalCharges
    }
  },
  created () {
    this.getRates()
  }
}
</script>

<style>
.sub-table tr {
  background-color: #f8f8f8;
}
.confirm_delete_table {
  border-collapse: collapse;
  border: 2px solid rgb(140 140 140);
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
  max-width: fit-content;
}
.confirm_delete_td_th {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
}
.delete-dialog {
 width: fit-content !important;
}
</style>
